import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ShippingDocs from './shipping-docs';
import ShippingDocsDetail from './shipping-docs-detail';
import ShippingDocsUpdate from './shipping-docs-update';
import ShippingDocsDeleteDialog from './shipping-docs-delete-dialog';

const ShippingDocsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ShippingDocs />} />
    <Route path="new" element={<ShippingDocsUpdate />} />
    <Route path=":id">
      <Route index element={<ShippingDocsDetail />} />
      <Route path="edit" element={<ShippingDocsUpdate />} />
      <Route path="delete" element={<ShippingDocsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ShippingDocsRoutes;
