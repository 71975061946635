import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductItemPrice from './product-item-price';
import ProductItemPriceDetail from './product-item-price-detail';
import ProductItemPriceUpdate from './product-item-price-update';
import ProductItemPriceDeleteDialog from './product-item-price-delete-dialog';

const ProductItemPriceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductItemPrice />} />
    <Route path="new" element={<ProductItemPriceUpdate />} />
    <Route path=":id">
      <Route index element={<ProductItemPriceDetail />} />
      <Route path="edit" element={<ProductItemPriceUpdate />} />
      <Route path="delete" element={<ProductItemPriceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductItemPriceRoutes;
