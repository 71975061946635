import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PurchaseInvoice from './purchase-invoice';
import PurchaseInvoiceDetail from './purchase-invoice-detail';
import PurchaseInvoiceUpdate from './purchase-invoice-update';
import PurchaseInvoiceDeleteDialog from './purchase-invoice-delete-dialog';

const PurchaseInvoiceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PurchaseInvoice />} />
    <Route path="new" element={<PurchaseInvoiceUpdate />} />
    <Route path=":id">
      <Route index element={<PurchaseInvoiceDetail />} />
      <Route path="edit" element={<PurchaseInvoiceUpdate />} />
      <Route path="delete" element={<PurchaseInvoiceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PurchaseInvoiceRoutes;
