import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CustomerPriceList from './customer-price-list';
import CustomerPriceListDetail from './customer-price-list-detail';
import CustomerPriceListUpdate from './customer-price-list-update';
import CustomerPriceListDeleteDialog from './customer-price-list-delete-dialog';

const CustomerPriceListRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CustomerPriceList />} />
    <Route path="new" element={<CustomerPriceListUpdate />} />
    <Route path=":id">
      <Route index element={<CustomerPriceListDetail />} />
      <Route path="edit" element={<CustomerPriceListUpdate />} />
      <Route path="delete" element={<CustomerPriceListDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CustomerPriceListRoutes;
