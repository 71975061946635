import { IFactory } from 'app/shared/model/factory.model';
import { IProductPackList } from 'app/shared/model/product-pack-list.model';
import { ISalesContract } from 'app/shared/model/sales-contract.model';

export interface IBill {
  id?: string;
  billNo?: string;
  taxNo?: string;
  soldTo?: string;
  from?: string;
  toAddress?: string;
  totalHQ?: number;
  estimatedDeparture?: string;
  factory?: IFactory | null;
  productPackLists?: IProductPackList[] | null;
  salesContract?: ISalesContract | null;
}

export const defaultValue: Readonly<IBill> = {};
