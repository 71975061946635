import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ShipBooking from './ship-booking';
import ShipBookingDetail from './ship-booking-detail';
import ShipBookingUpdate from './ship-booking-update';
import ShipBookingDeleteDialog from './ship-booking-delete-dialog';

const ShipBookingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ShipBooking />} />
    <Route path="new" element={<ShipBookingUpdate />} />
    <Route path=":id">
      <Route index element={<ShipBookingDetail />} />
      <Route path="edit" element={<ShipBookingUpdate />} />
      <Route path="delete" element={<ShipBookingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ShipBookingRoutes;
