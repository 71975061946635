import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IShippingDocs } from 'app/shared/model/shipping-docs.model';
import { getEntities as getShippingDocs } from 'app/entities/shipping-docs/shipping-docs.reducer';
import { IArrivalNotice } from 'app/shared/model/arrival-notice.model';
import { getEntity, updateEntity, createEntity, reset } from './arrival-notice.reducer';

export const ArrivalNoticeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const shippingDocs = useAppSelector(state => state.shippingDocs.entities);
  const arrivalNoticeEntity = useAppSelector(state => state.arrivalNotice.entity);
  const loading = useAppSelector(state => state.arrivalNotice.loading);
  const updating = useAppSelector(state => state.arrivalNotice.updating);
  const updateSuccess = useAppSelector(state => state.arrivalNotice.updateSuccess);

  const handleClose = () => {
    navigate('/arrival-notice');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getShippingDocs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    values.etd = convertDateTimeToServer(values.etd);
    values.eta = convertDateTimeToServer(values.eta);
    values.issuedPlace = convertDateTimeToServer(values.issuedPlace);
    values.invoiceDate = convertDateTimeToServer(values.invoiceDate);
    if (values.pkgNo !== undefined && typeof values.pkgNo !== 'number') {
      values.pkgNo = Number(values.pkgNo);
    }
    if (values.totalAmount !== undefined && typeof values.totalAmount !== 'number') {
      values.totalAmount = Number(values.totalAmount);
    }

    const entity = {
      ...arrivalNoticeEntity,
      ...values,
      shippingDocs: shippingDocs.find(it => it.id.toString() === values.shippingDocs.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          etd: displayDefaultDateTime(),
          eta: displayDefaultDateTime(),
          issuedPlace: displayDefaultDateTime(),
          invoiceDate: displayDefaultDateTime(),
        }
      : {
          ...arrivalNoticeEntity,
          etd: convertDateTimeFromServer(arrivalNoticeEntity.etd),
          eta: convertDateTimeFromServer(arrivalNoticeEntity.eta),
          issuedPlace: convertDateTimeFromServer(arrivalNoticeEntity.issuedPlace),
          invoiceDate: convertDateTimeFromServer(arrivalNoticeEntity.invoiceDate),
          shippingDocs: arrivalNoticeEntity?.shippingDocs?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="suretracBooksApp.arrivalNotice.home.createOrEditLabel" data-cy="ArrivalNoticeCreateUpdateHeading">
            <Translate contentKey="suretracBooksApp.arrivalNotice.home.createOrEditLabel">Create or edit a ArrivalNotice</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="arrival-notice-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.lotNo')}
                id="arrival-notice-lotNo"
                name="lotNo"
                data-cy="lotNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.masterBLNo')}
                id="arrival-notice-masterBLNo"
                name="masterBLNo"
                data-cy="masterBLNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.preparedBy')}
                id="arrival-notice-preparedBy"
                name="preparedBy"
                data-cy="preparedBy"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.consignee')}
                id="arrival-notice-consignee"
                name="consignee"
                data-cy="consignee"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.amsBLNo')}
                id="arrival-notice-amsBLNo"
                name="amsBLNo"
                data-cy="amsBLNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.vesselVoyNo')}
                id="arrival-notice-vesselVoyNo"
                name="vesselVoyNo"
                data-cy="vesselVoyNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.moveType')}
                id="arrival-notice-moveType"
                name="moveType"
                data-cy="moveType"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.portOfLoading')}
                id="arrival-notice-portOfLoading"
                name="portOfLoading"
                data-cy="portOfLoading"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.etd')}
                id="arrival-notice-etd"
                name="etd"
                data-cy="etd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.eta')}
                id="arrival-notice-eta"
                name="eta"
                data-cy="eta"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.iTNo')}
                id="arrival-notice-iTNo"
                name="iTNo"
                data-cy="iTNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.issuedPlace')}
                id="arrival-notice-issuedPlace"
                name="issuedPlace"
                data-cy="issuedPlace"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.invoiceDate')}
                id="arrival-notice-invoiceDate"
                name="invoiceDate"
                data-cy="invoiceDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.containerNo')}
                id="arrival-notice-containerNo"
                name="containerNo"
                data-cy="containerNo"
                type="text"
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.containerSize')}
                id="arrival-notice-containerSize"
                name="containerSize"
                data-cy="containerSize"
                type="text"
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.sealNo')}
                id="arrival-notice-sealNo"
                name="sealNo"
                data-cy="sealNo"
                type="text"
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.pkgNo')}
                id="arrival-notice-pkgNo"
                name="pkgNo"
                data-cy="pkgNo"
                type="text"
              />
              <ValidatedField
                label={translate('suretracBooksApp.arrivalNotice.totalAmount')}
                id="arrival-notice-totalAmount"
                name="totalAmount"
                data-cy="totalAmount"
                type="text"
              />
              <ValidatedField
                id="arrival-notice-shippingDocs"
                name="shippingDocs"
                data-cy="shippingDocs"
                label={translate('suretracBooksApp.arrivalNotice.shippingDocs')}
                type="select"
              >
                <option value="" key="0" />
                {shippingDocs
                  ? shippingDocs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/arrival-notice" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ArrivalNoticeUpdate;
