import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BillOfLading from './bill-of-lading';
import BillOfLadingDetail from './bill-of-lading-detail';
import BillOfLadingUpdate from './bill-of-lading-update';
import BillOfLadingDeleteDialog from './bill-of-lading-delete-dialog';

const BillOfLadingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BillOfLading />} />
    <Route path="new" element={<BillOfLadingUpdate />} />
    <Route path=":id">
      <Route index element={<BillOfLadingDetail />} />
      <Route path="edit" element={<BillOfLadingUpdate />} />
      <Route path="delete" element={<BillOfLadingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BillOfLadingRoutes;
