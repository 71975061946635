import { IFactory } from 'app/shared/model/factory.model';
import { IProductPackList } from 'app/shared/model/product-pack-list.model';
import { ISalesContract } from 'app/shared/model/sales-contract.model';

export interface IPurchaseOrder {
  id?: string;
  poNumber?: string;
  factory?: IFactory | null;
  productPackLists?: IProductPackList[] | null;
  salesContract?: ISalesContract | null;
}

export const defaultValue: Readonly<IPurchaseOrder> = {};
