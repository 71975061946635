import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './arrival-notice.reducer';

export const ArrivalNoticeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const arrivalNoticeEntity = useAppSelector(state => state.arrivalNotice.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="arrivalNoticeDetailsHeading">
          <Translate contentKey="suretracBooksApp.arrivalNotice.detail.title">ArrivalNotice</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.id}</dd>
          <dt>
            <span id="lotNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.lotNo">Lot No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.lotNo}</dd>
          <dt>
            <span id="masterBLNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.masterBLNo">Master BL No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.masterBLNo}</dd>
          <dt>
            <span id="preparedBy">
              <Translate contentKey="suretracBooksApp.arrivalNotice.preparedBy">Prepared By</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.preparedBy}</dd>
          <dt>
            <span id="consignee">
              <Translate contentKey="suretracBooksApp.arrivalNotice.consignee">Consignee</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.consignee}</dd>
          <dt>
            <span id="amsBLNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.amsBLNo">Ams BL No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.amsBLNo}</dd>
          <dt>
            <span id="vesselVoyNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.vesselVoyNo">Vessel Voy No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.vesselVoyNo}</dd>
          <dt>
            <span id="moveType">
              <Translate contentKey="suretracBooksApp.arrivalNotice.moveType">Move Type</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.moveType}</dd>
          <dt>
            <span id="portOfLoading">
              <Translate contentKey="suretracBooksApp.arrivalNotice.portOfLoading">Port Of Loading</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.portOfLoading}</dd>
          <dt>
            <span id="etd">
              <Translate contentKey="suretracBooksApp.arrivalNotice.etd">Etd</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.etd ? <TextFormat value={arrivalNoticeEntity.etd} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="eta">
              <Translate contentKey="suretracBooksApp.arrivalNotice.eta">Eta</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.eta ? <TextFormat value={arrivalNoticeEntity.eta} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="iTNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.iTNo">I T No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.iTNo}</dd>
          <dt>
            <span id="issuedPlace">
              <Translate contentKey="suretracBooksApp.arrivalNotice.issuedPlace">Issued Place</Translate>
            </span>
          </dt>
          <dd>
            {arrivalNoticeEntity.issuedPlace ? (
              <TextFormat value={arrivalNoticeEntity.issuedPlace} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="invoiceDate">
              <Translate contentKey="suretracBooksApp.arrivalNotice.invoiceDate">Invoice Date</Translate>
            </span>
          </dt>
          <dd>
            {arrivalNoticeEntity.invoiceDate ? (
              <TextFormat value={arrivalNoticeEntity.invoiceDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="containerNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.containerNo">Container No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.containerNo}</dd>
          <dt>
            <span id="containerSize">
              <Translate contentKey="suretracBooksApp.arrivalNotice.containerSize">Container Size</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.containerSize}</dd>
          <dt>
            <span id="sealNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.sealNo">Seal No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.sealNo}</dd>
          <dt>
            <span id="pkgNo">
              <Translate contentKey="suretracBooksApp.arrivalNotice.pkgNo">Pkg No</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.pkgNo}</dd>
          <dt>
            <span id="totalAmount">
              <Translate contentKey="suretracBooksApp.arrivalNotice.totalAmount">Total Amount</Translate>
            </span>
          </dt>
          <dd>{arrivalNoticeEntity.totalAmount}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.arrivalNotice.shippingDocs">Shipping Docs</Translate>
          </dt>
          <dd>{arrivalNoticeEntity.shippingDocs ? arrivalNoticeEntity.shippingDocs.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/arrival-notice" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/arrival-notice/${arrivalNoticeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ArrivalNoticeDetail;
