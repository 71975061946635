import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './invoice.reducer';

export const InvoiceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const invoiceEntity = useAppSelector(state => state.invoice.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="invoiceDetailsHeading">
          <Translate contentKey="suretracBooksApp.invoice.detail.title">Invoice</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.id}</dd>
          <dt>
            <span id="invoiceNo">
              <Translate contentKey="suretracBooksApp.invoice.invoiceNo">Invoice No</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.invoiceNo}</dd>
          <dt>
            <span id="contractNo">
              <Translate contentKey="suretracBooksApp.invoice.contractNo">Contract No</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.contractNo}</dd>
          <dt>
            <span id="containerNo">
              <Translate contentKey="suretracBooksApp.invoice.containerNo">Container No</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.containerNo}</dd>
          <dt>
            <span id="billTo">
              <Translate contentKey="suretracBooksApp.invoice.billTo">Bill To</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.billTo}</dd>
          <dt>
            <span id="shipTo">
              <Translate contentKey="suretracBooksApp.invoice.shipTo">Ship To</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.shipTo}</dd>
          <dt>
            <span id="payment">
              <Translate contentKey="suretracBooksApp.invoice.payment">Payment</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.payment}</dd>
          <dt>
            <span id="balanceDue">
              <Translate contentKey="suretracBooksApp.invoice.balanceDue">Balance Due</Translate>
            </span>
          </dt>
          <dd>{invoiceEntity.balanceDue}</dd>
        </dl>
        <Button tag={Link} to="/invoice" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/invoice/${invoiceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InvoiceDetail;
