import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductPackList from './product-pack-list';
import ProductPackListDetail from './product-pack-list-detail';
import ProductPackListUpdate from './product-pack-list-update';
import ProductPackListDeleteDialog from './product-pack-list-delete-dialog';

const ProductPackListRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductPackList />} />
    <Route path="new" element={<ProductPackListUpdate />} />
    <Route path=":id">
      <Route index element={<ProductPackListDetail />} />
      <Route path="edit" element={<ProductPackListUpdate />} />
      <Route path="delete" element={<ProductPackListDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductPackListRoutes;
