import { IShippingDocs } from 'app/shared/model/shipping-docs.model';

export interface IBillOfLading {
  id?: string;
  billOfLadingNo?: string;
  consignee?: string;
  notifyParty?: string;
  placeOfReceipt?: string;
  portOfLoading?: string;
  vesselVoyage?: string;
  portOfDischarge?: string;
  containerNo?: string;
  sealNo?: string;
  description?: string;
  grossWeight?: number;
  measurement?: number;
  telexRelease?: boolean;
  shippingDocs?: IShippingDocs | null;
}

export const defaultValue: Readonly<IBillOfLading> = {
  telexRelease: false,
};
