import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './ship-booking.reducer';

export const ShipBooking = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const shipBookingList = useAppSelector(state => state.shipBooking.entities);
  const loading = useAppSelector(state => state.shipBooking.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="ship-booking-heading" data-cy="ShipBookingHeading">
        <Translate contentKey="suretracBooksApp.shipBooking.home.title">Ship Bookings</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="suretracBooksApp.shipBooking.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/ship-booking/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="suretracBooksApp.shipBooking.home.createLabel">Create new Ship Booking</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {shipBookingList && shipBookingList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('bookingId')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.bookingId">Booking Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('bookingId')} />
                </th>
                <th className="hand" onClick={sort('bookingType')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.bookingType">Booking Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('bookingType')} />
                </th>
                <th className="hand" onClick={sort('bookingDate')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.bookingDate">Booking Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('bookingDate')} />
                </th>
                <th className="hand" onClick={sort('containerType')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.containerType">Container Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('containerType')} />
                </th>
                <th className="hand" onClick={sort('consignee')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.consignee">Consignee</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('consignee')} />
                </th>
                <th className="hand" onClick={sort('placeOfReceipt')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.placeOfReceipt">Place Of Receipt</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('placeOfReceipt')} />
                </th>
                <th className="hand" onClick={sort('portOfLoading')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.portOfLoading">Port Of Loading</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('portOfLoading')} />
                </th>
                <th className="hand" onClick={sort('fobAt')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.fobAt">Fob At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('fobAt')} />
                </th>
                <th className="hand" onClick={sort('feeder')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.feeder">Feeder</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('feeder')} />
                </th>
                <th className="hand" onClick={sort('motherVessel')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.motherVessel">Mother Vessel</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('motherVessel')} />
                </th>
                <th className="hand" onClick={sort('portOfDischarge')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.portOfDischarge">Port Of Discharge</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('portOfDischarge')} />
                </th>
                <th className="hand" onClick={sort('portOfDelivery')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.portOfDelivery">Port Of Delivery</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('portOfDelivery')} />
                </th>
                <th className="hand" onClick={sort('finalDest')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.finalDest">Final Dest</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('finalDest')} />
                </th>
                <th className="hand" onClick={sort('etd')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.etd">Etd</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('etd')} />
                </th>
                <th className="hand" onClick={sort('eta')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.eta">Eta</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('eta')} />
                </th>
                <th className="hand" onClick={sort('cyDate')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.cyDate">Cy Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cyDate')} />
                </th>
                <th className="hand" onClick={sort('returnDate')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.returnDate">Return Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('returnDate')} />
                </th>
                <th className="hand" onClick={sort('cyYard')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.cyYard">Cy Yard</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cyYard')} />
                </th>
                <th className="hand" onClick={sort('contact')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.contact">Contact</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('contact')} />
                </th>
                <th className="hand" onClick={sort('returnYard')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.returnYard">Return Yard</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('returnYard')} />
                </th>
                <th className="hand" onClick={sort('yardRemark')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.yardRemark">Yard Remark</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('yardRemark')} />
                </th>
                <th className="hand" onClick={sort('closingTime')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.closingTime">Closing Time</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('closingTime')} />
                </th>
                <th className="hand" onClick={sort('carrierBookingNo')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.carrierBookingNo">Carrier Booking No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('carrierBookingNo')} />
                </th>
                <th className="hand" onClick={sort('carrier')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.carrier">Carrier</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('carrier')} />
                </th>
                <th className="hand" onClick={sort('cutOffTime')}>
                  <Translate contentKey="suretracBooksApp.shipBooking.cutOffTime">Cut Off Time</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('cutOffTime')} />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.shipBooking.shippingDocs">Shipping Docs</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {shipBookingList.map((shipBooking, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/ship-booking/${shipBooking.id}`} color="link" size="sm">
                      {shipBooking.id}
                    </Button>
                  </td>
                  <td>{shipBooking.bookingId}</td>
                  <td>{shipBooking.bookingType}</td>
                  <td>
                    {shipBooking.bookingDate ? <TextFormat type="date" value={shipBooking.bookingDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{shipBooking.containerType}</td>
                  <td>{shipBooking.consignee}</td>
                  <td>{shipBooking.placeOfReceipt}</td>
                  <td>{shipBooking.portOfLoading}</td>
                  <td>{shipBooking.fobAt}</td>
                  <td>{shipBooking.feeder}</td>
                  <td>{shipBooking.motherVessel}</td>
                  <td>{shipBooking.portOfDischarge}</td>
                  <td>{shipBooking.portOfDelivery}</td>
                  <td>{shipBooking.finalDest}</td>
                  <td>{shipBooking.etd ? <TextFormat type="date" value={shipBooking.etd} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{shipBooking.eta ? <TextFormat type="date" value={shipBooking.eta} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{shipBooking.cyDate ? <TextFormat type="date" value={shipBooking.cyDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>
                    {shipBooking.returnDate ? <TextFormat type="date" value={shipBooking.returnDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{shipBooking.cyYard}</td>
                  <td>{shipBooking.contact}</td>
                  <td>{shipBooking.returnYard}</td>
                  <td>{shipBooking.yardRemark}</td>
                  <td>
                    {shipBooking.closingTime ? <TextFormat type="date" value={shipBooking.closingTime} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{shipBooking.carrierBookingNo}</td>
                  <td>{shipBooking.carrier}</td>
                  <td>
                    {shipBooking.cutOffTime ? <TextFormat type="date" value={shipBooking.cutOffTime} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {shipBooking.shippingDocs ? (
                      <Link to={`/shipping-docs/${shipBooking.shippingDocs.id}`}>{shipBooking.shippingDocs.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/ship-booking/${shipBooking.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/ship-booking/${shipBooking.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (location.href = `/ship-booking/${shipBooking.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="suretracBooksApp.shipBooking.home.notFound">No Ship Bookings found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ShipBooking;
