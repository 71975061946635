import { IPurchaseInvoice } from 'app/shared/model/purchase-invoice.model';
import { IPurchaseOrder } from 'app/shared/model/purchase-order.model';
import { IBill } from 'app/shared/model/bill.model';
import { IInvoice } from 'app/shared/model/invoice.model';
import { IShippingDocs } from 'app/shared/model/shipping-docs.model';
import { IPaymentNotice } from 'app/shared/model/payment-notice.model';
import { IShippingNotice } from 'app/shared/model/shipping-notice.model';
import { IProductPackList } from 'app/shared/model/product-pack-list.model';
import { IUser } from 'app/shared/model/user.model';
import { ImportType } from 'app/shared/model/enumerations/import-type.model';

export interface ISalesContract {
  id?: string;
  contractId?: string;
  deposit?: number;
  blance?: number;
  shipmentPort?: string;
  dischargePlace?: string;
  piValidity?: number;
  placedDate?: string;
  moreOrLess?: number;
  totalPrice?: number;
  importType?: keyof typeof ImportType;
  terms?: string;
  purchaseInvoice?: IPurchaseInvoice | null;
  purchaseOrder?: IPurchaseOrder | null;
  bill?: IBill | null;
  invoice?: IInvoice | null;
  shippingDocs?: IShippingDocs | null;
  paymentNotices?: IPaymentNotice[] | null;
  shippingNotices?: IShippingNotice[] | null;
  productPackLists?: IProductPackList[] | null;
  user?: IUser;
}

export const defaultValue: Readonly<ISalesContract> = {};
