import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SalesContract from './sales-contract';
import SalesContractDetail from './sales-contract-detail';
import SalesContractUpdate from './sales-contract-update';
import SalesContractDeleteDialog from './sales-contract-delete-dialog';

const SalesContractRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SalesContract />} />
    <Route path="new" element={<SalesContractUpdate />} />
    <Route path=":id">
      <Route index element={<SalesContractDetail />} />
      <Route path="edit" element={<SalesContractUpdate />} />
      <Route path="delete" element={<SalesContractDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SalesContractRoutes;
