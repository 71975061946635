import { IUser } from 'app/shared/model/user.model';
import { IProductPackList } from 'app/shared/model/product-pack-list.model';
import { ISalesContract } from 'app/shared/model/sales-contract.model';

export interface IPurchaseInvoice {
  id?: string;
  piNumber?: string;
  user?: IUser;
  productPackLists?: IProductPackList[] | null;
  salesContract?: ISalesContract | null;
}

export const defaultValue: Readonly<IPurchaseInvoice> = {};
