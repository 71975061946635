import { IProductPackList } from 'app/shared/model/product-pack-list.model';
import { ISalesContract } from 'app/shared/model/sales-contract.model';

export interface IInvoice {
  id?: string;
  invoiceNo?: string;
  contractNo?: string;
  containerNo?: string;
  billTo?: string;
  shipTo?: string;
  payment?: number;
  balanceDue?: number;
  productPackLists?: IProductPackList[] | null;
  salesContract?: ISalesContract | null;
}

export const defaultValue: Readonly<IInvoice> = {};
