import { IFactory } from 'app/shared/model/factory.model';

export interface IProduct {
  id?: string;
  sku?: string;
  fet?: number;
  price?: number;
  threadWidthMm?: number;
  threadDepthMm?: number;
  threadDepth?: number;
  lisi?: string;
  app?: string;
  patternValue?: string;
  pr?: number;
  psize?: string;
  itemNo?: number;
  pictureContentType?: string | null;
  picture?: string | null;
  factory?: IFactory | null;
}

export const defaultValue: Readonly<IProduct> = {};
