import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ship-booking.reducer';

export const ShipBookingDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const shipBookingEntity = useAppSelector(state => state.shipBooking.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="shipBookingDetailsHeading">
          <Translate contentKey="suretracBooksApp.shipBooking.detail.title">ShipBooking</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.id}</dd>
          <dt>
            <span id="bookingId">
              <Translate contentKey="suretracBooksApp.shipBooking.bookingId">Booking Id</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.bookingId}</dd>
          <dt>
            <span id="bookingType">
              <Translate contentKey="suretracBooksApp.shipBooking.bookingType">Booking Type</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.bookingType}</dd>
          <dt>
            <span id="bookingDate">
              <Translate contentKey="suretracBooksApp.shipBooking.bookingDate">Booking Date</Translate>
            </span>
          </dt>
          <dd>
            {shipBookingEntity.bookingDate ? (
              <TextFormat value={shipBookingEntity.bookingDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="containerType">
              <Translate contentKey="suretracBooksApp.shipBooking.containerType">Container Type</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.containerType}</dd>
          <dt>
            <span id="consignee">
              <Translate contentKey="suretracBooksApp.shipBooking.consignee">Consignee</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.consignee}</dd>
          <dt>
            <span id="placeOfReceipt">
              <Translate contentKey="suretracBooksApp.shipBooking.placeOfReceipt">Place Of Receipt</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.placeOfReceipt}</dd>
          <dt>
            <span id="portOfLoading">
              <Translate contentKey="suretracBooksApp.shipBooking.portOfLoading">Port Of Loading</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.portOfLoading}</dd>
          <dt>
            <span id="fobAt">
              <Translate contentKey="suretracBooksApp.shipBooking.fobAt">Fob At</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.fobAt}</dd>
          <dt>
            <span id="feeder">
              <Translate contentKey="suretracBooksApp.shipBooking.feeder">Feeder</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.feeder}</dd>
          <dt>
            <span id="motherVessel">
              <Translate contentKey="suretracBooksApp.shipBooking.motherVessel">Mother Vessel</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.motherVessel}</dd>
          <dt>
            <span id="portOfDischarge">
              <Translate contentKey="suretracBooksApp.shipBooking.portOfDischarge">Port Of Discharge</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.portOfDischarge}</dd>
          <dt>
            <span id="portOfDelivery">
              <Translate contentKey="suretracBooksApp.shipBooking.portOfDelivery">Port Of Delivery</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.portOfDelivery}</dd>
          <dt>
            <span id="finalDest">
              <Translate contentKey="suretracBooksApp.shipBooking.finalDest">Final Dest</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.finalDest}</dd>
          <dt>
            <span id="etd">
              <Translate contentKey="suretracBooksApp.shipBooking.etd">Etd</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.etd ? <TextFormat value={shipBookingEntity.etd} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="eta">
              <Translate contentKey="suretracBooksApp.shipBooking.eta">Eta</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.eta ? <TextFormat value={shipBookingEntity.eta} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="cyDate">
              <Translate contentKey="suretracBooksApp.shipBooking.cyDate">Cy Date</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.cyDate ? <TextFormat value={shipBookingEntity.cyDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="returnDate">
              <Translate contentKey="suretracBooksApp.shipBooking.returnDate">Return Date</Translate>
            </span>
          </dt>
          <dd>
            {shipBookingEntity.returnDate ? <TextFormat value={shipBookingEntity.returnDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="cyYard">
              <Translate contentKey="suretracBooksApp.shipBooking.cyYard">Cy Yard</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.cyYard}</dd>
          <dt>
            <span id="contact">
              <Translate contentKey="suretracBooksApp.shipBooking.contact">Contact</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.contact}</dd>
          <dt>
            <span id="returnYard">
              <Translate contentKey="suretracBooksApp.shipBooking.returnYard">Return Yard</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.returnYard}</dd>
          <dt>
            <span id="yardRemark">
              <Translate contentKey="suretracBooksApp.shipBooking.yardRemark">Yard Remark</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.yardRemark}</dd>
          <dt>
            <span id="closingTime">
              <Translate contentKey="suretracBooksApp.shipBooking.closingTime">Closing Time</Translate>
            </span>
          </dt>
          <dd>
            {shipBookingEntity.closingTime ? (
              <TextFormat value={shipBookingEntity.closingTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="carrierBookingNo">
              <Translate contentKey="suretracBooksApp.shipBooking.carrierBookingNo">Carrier Booking No</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.carrierBookingNo}</dd>
          <dt>
            <span id="carrier">
              <Translate contentKey="suretracBooksApp.shipBooking.carrier">Carrier</Translate>
            </span>
          </dt>
          <dd>{shipBookingEntity.carrier}</dd>
          <dt>
            <span id="cutOffTime">
              <Translate contentKey="suretracBooksApp.shipBooking.cutOffTime">Cut Off Time</Translate>
            </span>
          </dt>
          <dd>
            {shipBookingEntity.cutOffTime ? <TextFormat value={shipBookingEntity.cutOffTime} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="suretracBooksApp.shipBooking.shippingDocs">Shipping Docs</Translate>
          </dt>
          <dd>{shipBookingEntity.shippingDocs ? shipBookingEntity.shippingDocs.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/ship-booking" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ship-booking/${shipBookingEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ShipBookingDetail;
