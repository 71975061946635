import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IShippingDocs } from 'app/shared/model/shipping-docs.model';
import { getEntities as getShippingDocs } from 'app/entities/shipping-docs/shipping-docs.reducer';
import { IBillOfLading } from 'app/shared/model/bill-of-lading.model';
import { getEntity, updateEntity, createEntity, reset } from './bill-of-lading.reducer';

export const BillOfLadingUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const shippingDocs = useAppSelector(state => state.shippingDocs.entities);
  const billOfLadingEntity = useAppSelector(state => state.billOfLading.entity);
  const loading = useAppSelector(state => state.billOfLading.loading);
  const updating = useAppSelector(state => state.billOfLading.updating);
  const updateSuccess = useAppSelector(state => state.billOfLading.updateSuccess);

  const handleClose = () => {
    navigate('/bill-of-lading');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getShippingDocs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.grossWeight !== undefined && typeof values.grossWeight !== 'number') {
      values.grossWeight = Number(values.grossWeight);
    }
    if (values.measurement !== undefined && typeof values.measurement !== 'number') {
      values.measurement = Number(values.measurement);
    }

    const entity = {
      ...billOfLadingEntity,
      ...values,
      shippingDocs: shippingDocs.find(it => it.id.toString() === values.shippingDocs.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...billOfLadingEntity,
          shippingDocs: billOfLadingEntity?.shippingDocs?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="suretracBooksApp.billOfLading.home.createOrEditLabel" data-cy="BillOfLadingCreateUpdateHeading">
            <Translate contentKey="suretracBooksApp.billOfLading.home.createOrEditLabel">Create or edit a BillOfLading</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bill-of-lading-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.billOfLadingNo')}
                id="bill-of-lading-billOfLadingNo"
                name="billOfLadingNo"
                data-cy="billOfLadingNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.consignee')}
                id="bill-of-lading-consignee"
                name="consignee"
                data-cy="consignee"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.notifyParty')}
                id="bill-of-lading-notifyParty"
                name="notifyParty"
                data-cy="notifyParty"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.placeOfReceipt')}
                id="bill-of-lading-placeOfReceipt"
                name="placeOfReceipt"
                data-cy="placeOfReceipt"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.portOfLoading')}
                id="bill-of-lading-portOfLoading"
                name="portOfLoading"
                data-cy="portOfLoading"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.vesselVoyage')}
                id="bill-of-lading-vesselVoyage"
                name="vesselVoyage"
                data-cy="vesselVoyage"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.portOfDischarge')}
                id="bill-of-lading-portOfDischarge"
                name="portOfDischarge"
                data-cy="portOfDischarge"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.containerNo')}
                id="bill-of-lading-containerNo"
                name="containerNo"
                data-cy="containerNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.sealNo')}
                id="bill-of-lading-sealNo"
                name="sealNo"
                data-cy="sealNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.description')}
                id="bill-of-lading-description"
                name="description"
                data-cy="description"
                type="textarea"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.grossWeight')}
                id="bill-of-lading-grossWeight"
                name="grossWeight"
                data-cy="grossWeight"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.measurement')}
                id="bill-of-lading-measurement"
                name="measurement"
                data-cy="measurement"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.billOfLading.telexRelease')}
                id="bill-of-lading-telexRelease"
                name="telexRelease"
                data-cy="telexRelease"
                check
                type="checkbox"
              />
              <ValidatedField
                id="bill-of-lading-shippingDocs"
                name="shippingDocs"
                data-cy="shippingDocs"
                label={translate('suretracBooksApp.billOfLading.shippingDocs')}
                type="select"
              >
                <option value="" key="0" />
                {shippingDocs
                  ? shippingDocs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/bill-of-lading" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BillOfLadingUpdate;
