import { IProductItemPrice } from 'app/shared/model/product-item-price.model';
import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { IPurchaseInvoice } from 'app/shared/model/purchase-invoice.model';
import { IPurchaseOrder } from 'app/shared/model/purchase-order.model';
import { IBill } from 'app/shared/model/bill.model';
import { IInvoice } from 'app/shared/model/invoice.model';

export interface IProductPackList {
  id?: string;
  productItemPrices?: IProductItemPrice[] | null;
  salesContract?: ISalesContract | null;
  purchaseInvoice?: IPurchaseInvoice | null;
  purchaseOrder?: IPurchaseOrder | null;
  bill?: IBill | null;
  invoice?: IInvoice | null;
}

export const defaultValue: Readonly<IProductPackList> = {};
