import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductBasePrice from './product-base-price';
import ProductBasePriceDetail from './product-base-price-detail';
import ProductBasePriceUpdate from './product-base-price-update';
import ProductBasePriceDeleteDialog from './product-base-price-delete-dialog';

const ProductBasePriceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductBasePrice />} />
    <Route path="new" element={<ProductBasePriceUpdate />} />
    <Route path=":id">
      <Route index element={<ProductBasePriceDetail />} />
      <Route path="edit" element={<ProductBasePriceUpdate />} />
      <Route path="delete" element={<ProductBasePriceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductBasePriceRoutes;
