import { IShipBooking } from 'app/shared/model/ship-booking.model';
import { ScheduledAlertType } from 'app/shared/model/enumerations/scheduled-alert-type.model';

export interface IScheduledAlert {
  id?: string;
  noticeDate?: string;
  eventType?: keyof typeof ScheduledAlertType;
  message?: string;
  shipBooking?: IShipBooking | null;
}

export const defaultValue: Readonly<IScheduledAlert> = {};
