import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Product from './product';
import Factory from './factory';
import ProductItemPrice from './product-item-price';
import ProductPackList from './product-pack-list';
import ShippingNotice from './shipping-notice';
import PaymentNotice from './payment-notice';
import ScheduledAlert from './scheduled-alert';
import SalesContract from './sales-contract';
import PurchaseInvoice from './purchase-invoice';
import PurchaseOrder from './purchase-order';
import Bill from './bill';
import Invoice from './invoice';
import ProductBasePrice from './product-base-price';
import RegionalPriceList from './regional-price-list';
import CustomerPriceList from './customer-price-list';
import CustomerDetails from './customer-details';
import ShipBooking from './ship-booking';
import BillOfLading from './bill-of-lading';
import ArrivalNotice from './arrival-notice';
import ShippingDocs from './shipping-docs';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="product/*" element={<Product />} />
        <Route path="factory/*" element={<Factory />} />
        <Route path="product-item-price/*" element={<ProductItemPrice />} />
        <Route path="product-pack-list/*" element={<ProductPackList />} />
        <Route path="shipping-notice/*" element={<ShippingNotice />} />
        <Route path="payment-notice/*" element={<PaymentNotice />} />
        <Route path="scheduled-alert/*" element={<ScheduledAlert />} />
        <Route path="sales-contract/*" element={<SalesContract />} />
        <Route path="purchase-invoice/*" element={<PurchaseInvoice />} />
        <Route path="purchase-order/*" element={<PurchaseOrder />} />
        <Route path="bill/*" element={<Bill />} />
        <Route path="invoice/*" element={<Invoice />} />
        <Route path="product-base-price/*" element={<ProductBasePrice />} />
        <Route path="regional-price-list/*" element={<RegionalPriceList />} />
        <Route path="customer-price-list/*" element={<CustomerPriceList />} />
        <Route path="customer-details/*" element={<CustomerDetails />} />
        <Route path="ship-booking/*" element={<ShipBooking />} />
        <Route path="bill-of-lading/*" element={<BillOfLading />} />
        <Route path="arrival-notice/*" element={<ArrivalNotice />} />
        <Route path="shipping-docs/*" element={<ShippingDocs />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
