import { IShippingDocs } from 'app/shared/model/shipping-docs.model';
import { IScheduledAlert } from 'app/shared/model/scheduled-alert.model';

export interface IShipBooking {
  id?: string;
  bookingId?: string;
  bookingType?: string;
  bookingDate?: string;
  containerType?: string;
  consignee?: string;
  placeOfReceipt?: string;
  portOfLoading?: string;
  fobAt?: string;
  feeder?: string;
  motherVessel?: string;
  portOfDischarge?: string;
  portOfDelivery?: string;
  finalDest?: string;
  etd?: string;
  eta?: string;
  cyDate?: string;
  returnDate?: string;
  cyYard?: string;
  contact?: string;
  returnYard?: string;
  yardRemark?: string;
  closingTime?: string;
  carrierBookingNo?: number;
  carrier?: string;
  cutOffTime?: string;
  shippingDocs?: IShippingDocs | null;
  scheduledAlerts?: IScheduledAlert[] | null;
}

export const defaultValue: Readonly<IShipBooking> = {};
