import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ShippingNotice from './shipping-notice';
import ShippingNoticeDetail from './shipping-notice-detail';
import ShippingNoticeUpdate from './shipping-notice-update';
import ShippingNoticeDeleteDialog from './shipping-notice-delete-dialog';

const ShippingNoticeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ShippingNotice />} />
    <Route path="new" element={<ShippingNoticeUpdate />} />
    <Route path=":id">
      <Route index element={<ShippingNoticeDetail />} />
      <Route path="edit" element={<ShippingNoticeUpdate />} />
      <Route path="delete" element={<ShippingNoticeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ShippingNoticeRoutes;
