import { IRegionalPriceList } from 'app/shared/model/regional-price-list.model';
import { ICustomerPriceList } from 'app/shared/model/customer-price-list.model';

export interface IProductBasePrice {
  id?: string;
  sku?: string;
  price?: number;
  regionalPriceList?: IRegionalPriceList | null;
  customerPriceList?: ICustomerPriceList | null;
}

export const defaultValue: Readonly<IProductBasePrice> = {};
