import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { getEntities as getSalesContracts } from 'app/entities/sales-contract/sales-contract.reducer';
import { IShipBooking } from 'app/shared/model/ship-booking.model';
import { getEntities as getShipBookings } from 'app/entities/ship-booking/ship-booking.reducer';
import { IBillOfLading } from 'app/shared/model/bill-of-lading.model';
import { getEntities as getBillOfLadings } from 'app/entities/bill-of-lading/bill-of-lading.reducer';
import { IArrivalNotice } from 'app/shared/model/arrival-notice.model';
import { getEntities as getArrivalNotices } from 'app/entities/arrival-notice/arrival-notice.reducer';
import { IShippingDocs } from 'app/shared/model/shipping-docs.model';
import { getEntity, updateEntity, createEntity, reset } from './shipping-docs.reducer';

export const ShippingDocsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const salesContracts = useAppSelector(state => state.salesContract.entities);
  const shipBookings = useAppSelector(state => state.shipBooking.entities);
  const billOfLadings = useAppSelector(state => state.billOfLading.entities);
  const arrivalNotices = useAppSelector(state => state.arrivalNotice.entities);
  const shippingDocsEntity = useAppSelector(state => state.shippingDocs.entity);
  const loading = useAppSelector(state => state.shippingDocs.loading);
  const updating = useAppSelector(state => state.shippingDocs.updating);
  const updateSuccess = useAppSelector(state => state.shippingDocs.updateSuccess);

  const handleClose = () => {
    navigate('/shipping-docs');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSalesContracts({}));
    dispatch(getShipBookings({}));
    dispatch(getBillOfLadings({}));
    dispatch(getArrivalNotices({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    const entity = {
      ...shippingDocsEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...shippingDocsEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="suretracBooksApp.shippingDocs.home.createOrEditLabel" data-cy="ShippingDocsCreateUpdateHeading">
            <Translate contentKey="suretracBooksApp.shippingDocs.home.createOrEditLabel">Create or edit a ShippingDocs</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="shipping-docs-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/shipping-docs" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ShippingDocsUpdate;
