import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './product.reducer';

export const ProductDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const productEntity = useAppSelector(state => state.product.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="productDetailsHeading">
          <Translate contentKey="suretracBooksApp.product.detail.title">Product</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{productEntity.id}</dd>
          <dt>
            <span id="sku">
              <Translate contentKey="suretracBooksApp.product.sku">Sku</Translate>
            </span>
          </dt>
          <dd>{productEntity.sku}</dd>
          <dt>
            <span id="fet">
              <Translate contentKey="suretracBooksApp.product.fet">Fet</Translate>
            </span>
          </dt>
          <dd>{productEntity.fet}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="suretracBooksApp.product.price">Price</Translate>
            </span>
          </dt>
          <dd>{productEntity.price}</dd>
          <dt>
            <span id="threadWidthMm">
              <Translate contentKey="suretracBooksApp.product.threadWidthMm">Thread Width Mm</Translate>
            </span>
          </dt>
          <dd>{productEntity.threadWidthMm}</dd>
          <dt>
            <span id="threadDepthMm">
              <Translate contentKey="suretracBooksApp.product.threadDepthMm">Thread Depth Mm</Translate>
            </span>
          </dt>
          <dd>{productEntity.threadDepthMm}</dd>
          <dt>
            <span id="threadDepth">
              <Translate contentKey="suretracBooksApp.product.threadDepth">Thread Depth</Translate>
            </span>
          </dt>
          <dd>{productEntity.threadDepth}</dd>
          <dt>
            <span id="lisi">
              <Translate contentKey="suretracBooksApp.product.lisi">Lisi</Translate>
            </span>
          </dt>
          <dd>{productEntity.lisi}</dd>
          <dt>
            <span id="app">
              <Translate contentKey="suretracBooksApp.product.app">App</Translate>
            </span>
          </dt>
          <dd>{productEntity.app}</dd>
          <dt>
            <span id="patternValue">
              <Translate contentKey="suretracBooksApp.product.patternValue">Pattern Value</Translate>
            </span>
          </dt>
          <dd>{productEntity.patternValue}</dd>
          <dt>
            <span id="pr">
              <Translate contentKey="suretracBooksApp.product.pr">Pr</Translate>
            </span>
          </dt>
          <dd>{productEntity.pr}</dd>
          <dt>
            <span id="psize">
              <Translate contentKey="suretracBooksApp.product.psize">Psize</Translate>
            </span>
          </dt>
          <dd>{productEntity.psize}</dd>
          <dt>
            <span id="itemNo">
              <Translate contentKey="suretracBooksApp.product.itemNo">Item No</Translate>
            </span>
          </dt>
          <dd>{productEntity.itemNo}</dd>
          <dt>
            <span id="picture">
              <Translate contentKey="suretracBooksApp.product.picture">Picture</Translate>
            </span>
          </dt>
          <dd>
            {productEntity.picture ? (
              <div>
                {productEntity.pictureContentType ? (
                  <a onClick={openFile(productEntity.pictureContentType, productEntity.picture)}>
                    <img src={`data:${productEntity.pictureContentType};base64,${productEntity.picture}`} style={{ maxHeight: '30px' }} />
                  </a>
                ) : null}
                <span>
                  {productEntity.pictureContentType}, {byteSize(productEntity.picture)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="suretracBooksApp.product.factory">Factory</Translate>
          </dt>
          <dd>{productEntity.factory ? productEntity.factory.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/product" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/product/${productEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProductDetail;
