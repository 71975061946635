import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { ShippingEventType } from 'app/shared/model/enumerations/shipping-event-type.model';

export interface IShippingNotice {
  id?: string;
  noticeDate?: string;
  eventType?: keyof typeof ShippingEventType;
  message?: string;
  salesContract?: ISalesContract | null;
}

export const defaultValue: Readonly<IShippingNotice> = {};
