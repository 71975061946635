import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { getEntities as getSalesContracts } from 'app/entities/sales-contract/sales-contract.reducer';
import { IPurchaseInvoice } from 'app/shared/model/purchase-invoice.model';
import { getEntities as getPurchaseInvoices } from 'app/entities/purchase-invoice/purchase-invoice.reducer';
import { IPurchaseOrder } from 'app/shared/model/purchase-order.model';
import { getEntities as getPurchaseOrders } from 'app/entities/purchase-order/purchase-order.reducer';
import { IBill } from 'app/shared/model/bill.model';
import { getEntities as getBills } from 'app/entities/bill/bill.reducer';
import { IInvoice } from 'app/shared/model/invoice.model';
import { getEntities as getInvoices } from 'app/entities/invoice/invoice.reducer';
import { IProductPackList } from 'app/shared/model/product-pack-list.model';
import { getEntity, updateEntity, createEntity, reset } from './product-pack-list.reducer';

export const ProductPackListUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const salesContracts = useAppSelector(state => state.salesContract.entities);
  const purchaseInvoices = useAppSelector(state => state.purchaseInvoice.entities);
  const purchaseOrders = useAppSelector(state => state.purchaseOrder.entities);
  const bills = useAppSelector(state => state.bill.entities);
  const invoices = useAppSelector(state => state.invoice.entities);
  const productPackListEntity = useAppSelector(state => state.productPackList.entity);
  const loading = useAppSelector(state => state.productPackList.loading);
  const updating = useAppSelector(state => state.productPackList.updating);
  const updateSuccess = useAppSelector(state => state.productPackList.updateSuccess);

  const handleClose = () => {
    navigate('/product-pack-list');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSalesContracts({}));
    dispatch(getPurchaseInvoices({}));
    dispatch(getPurchaseOrders({}));
    dispatch(getBills({}));
    dispatch(getInvoices({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    const entity = {
      ...productPackListEntity,
      ...values,
      salesContract: salesContracts.find(it => it.id.toString() === values.salesContract.toString()),
      purchaseInvoice: purchaseInvoices.find(it => it.id.toString() === values.purchaseInvoice.toString()),
      purchaseOrder: purchaseOrders.find(it => it.id.toString() === values.purchaseOrder.toString()),
      bill: bills.find(it => it.id.toString() === values.bill.toString()),
      invoice: invoices.find(it => it.id.toString() === values.invoice.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...productPackListEntity,
          salesContract: productPackListEntity?.salesContract?.id,
          purchaseInvoice: productPackListEntity?.purchaseInvoice?.id,
          purchaseOrder: productPackListEntity?.purchaseOrder?.id,
          bill: productPackListEntity?.bill?.id,
          invoice: productPackListEntity?.invoice?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="suretracBooksApp.productPackList.home.createOrEditLabel" data-cy="ProductPackListCreateUpdateHeading">
            <Translate contentKey="suretracBooksApp.productPackList.home.createOrEditLabel">Create or edit a ProductPackList</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="product-pack-list-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="product-pack-list-salesContract"
                name="salesContract"
                data-cy="salesContract"
                label={translate('suretracBooksApp.productPackList.salesContract')}
                type="select"
              >
                <option value="" key="0" />
                {salesContracts
                  ? salesContracts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-pack-list-purchaseInvoice"
                name="purchaseInvoice"
                data-cy="purchaseInvoice"
                label={translate('suretracBooksApp.productPackList.purchaseInvoice')}
                type="select"
              >
                <option value="" key="0" />
                {purchaseInvoices
                  ? purchaseInvoices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-pack-list-purchaseOrder"
                name="purchaseOrder"
                data-cy="purchaseOrder"
                label={translate('suretracBooksApp.productPackList.purchaseOrder')}
                type="select"
              >
                <option value="" key="0" />
                {purchaseOrders
                  ? purchaseOrders.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-pack-list-bill"
                name="bill"
                data-cy="bill"
                label={translate('suretracBooksApp.productPackList.bill')}
                type="select"
              >
                <option value="" key="0" />
                {bills
                  ? bills.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-pack-list-invoice"
                name="invoice"
                data-cy="invoice"
                label={translate('suretracBooksApp.productPackList.invoice')}
                type="select"
              >
                <option value="" key="0" />
                {invoices
                  ? invoices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/product-pack-list" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductPackListUpdate;
