import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RegionalPriceList from './regional-price-list';
import RegionalPriceListDetail from './regional-price-list-detail';
import RegionalPriceListUpdate from './regional-price-list-update';
import RegionalPriceListDeleteDialog from './regional-price-list-delete-dialog';

const RegionalPriceListRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RegionalPriceList />} />
    <Route path="new" element={<RegionalPriceListUpdate />} />
    <Route path=":id">
      <Route index element={<RegionalPriceListDetail />} />
      <Route path="edit" element={<RegionalPriceListUpdate />} />
      <Route path="delete" element={<RegionalPriceListDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RegionalPriceListRoutes;
