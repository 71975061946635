import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { IShipBooking } from 'app/shared/model/ship-booking.model';
import { IBillOfLading } from 'app/shared/model/bill-of-lading.model';
import { IArrivalNotice } from 'app/shared/model/arrival-notice.model';

export interface IShippingDocs {
  id?: string;
  salesContract?: ISalesContract | null;
  shipBooking?: IShipBooking | null;
  billOfLading?: IBillOfLading | null;
  arrivalNotice?: IArrivalNotice | null;
}

export const defaultValue: Readonly<IShippingDocs> = {};
