import { IProductPackList } from 'app/shared/model/product-pack-list.model';

export interface IProductItemPrice {
  id?: string;
  sku?: string;
  quantity?: number;
  price?: number;
  productPackList?: IProductPackList | null;
}

export const defaultValue: Readonly<IProductItemPrice> = {};
