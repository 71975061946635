import { IBill } from 'app/shared/model/bill.model';
import { IPurchaseOrder } from 'app/shared/model/purchase-order.model';
import { IProduct } from 'app/shared/model/product.model';

export interface IFactory {
  id?: string;
  name?: string;
  address?: string;
  bill?: IBill | null;
  purchaseOrder?: IPurchaseOrder | null;
  products?: IProduct[] | null;
}

export const defaultValue: Readonly<IFactory> = {};
