import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './sales-contract.reducer';

export const SalesContractDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const salesContractEntity = useAppSelector(state => state.salesContract.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="salesContractDetailsHeading">
          <Translate contentKey="suretracBooksApp.salesContract.detail.title">SalesContract</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.id}</dd>
          <dt>
            <span id="contractId">
              <Translate contentKey="suretracBooksApp.salesContract.contractId">Contract Id</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.contractId}</dd>
          <dt>
            <span id="deposit">
              <Translate contentKey="suretracBooksApp.salesContract.deposit">Deposit</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.deposit}</dd>
          <dt>
            <span id="blance">
              <Translate contentKey="suretracBooksApp.salesContract.blance">Blance</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.blance}</dd>
          <dt>
            <span id="shipmentPort">
              <Translate contentKey="suretracBooksApp.salesContract.shipmentPort">Shipment Port</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.shipmentPort}</dd>
          <dt>
            <span id="dischargePlace">
              <Translate contentKey="suretracBooksApp.salesContract.dischargePlace">Discharge Place</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.dischargePlace}</dd>
          <dt>
            <span id="piValidity">
              <Translate contentKey="suretracBooksApp.salesContract.piValidity">Pi Validity</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.piValidity}</dd>
          <dt>
            <span id="placedDate">
              <Translate contentKey="suretracBooksApp.salesContract.placedDate">Placed Date</Translate>
            </span>
          </dt>
          <dd>
            {salesContractEntity.placedDate ? (
              <TextFormat value={salesContractEntity.placedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="moreOrLess">
              <Translate contentKey="suretracBooksApp.salesContract.moreOrLess">More Or Less</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.moreOrLess}</dd>
          <dt>
            <span id="totalPrice">
              <Translate contentKey="suretracBooksApp.salesContract.totalPrice">Total Price</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.totalPrice}</dd>
          <dt>
            <span id="importType">
              <Translate contentKey="suretracBooksApp.salesContract.importType">Import Type</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.importType}</dd>
          <dt>
            <span id="terms">
              <Translate contentKey="suretracBooksApp.salesContract.terms">Terms</Translate>
            </span>
          </dt>
          <dd>{salesContractEntity.terms}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.salesContract.purchaseInvoice">Purchase Invoice</Translate>
          </dt>
          <dd>{salesContractEntity.purchaseInvoice ? salesContractEntity.purchaseInvoice.id : ''}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.salesContract.purchaseOrder">Purchase Order</Translate>
          </dt>
          <dd>{salesContractEntity.purchaseOrder ? salesContractEntity.purchaseOrder.id : ''}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.salesContract.bill">Bill</Translate>
          </dt>
          <dd>{salesContractEntity.bill ? salesContractEntity.bill.id : ''}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.salesContract.invoice">Invoice</Translate>
          </dt>
          <dd>{salesContractEntity.invoice ? salesContractEntity.invoice.id : ''}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.salesContract.shippingDocs">Shipping Docs</Translate>
          </dt>
          <dd>{salesContractEntity.shippingDocs ? salesContractEntity.shippingDocs.id : ''}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.salesContract.user">User</Translate>
          </dt>
          <dd>{salesContractEntity.user ? salesContractEntity.user.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/sales-contract" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sales-contract/${salesContractEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SalesContractDetail;
