import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PaymentNotice from './payment-notice';
import PaymentNoticeDetail from './payment-notice-detail';
import PaymentNoticeUpdate from './payment-notice-update';
import PaymentNoticeDeleteDialog from './payment-notice-delete-dialog';

const PaymentNoticeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PaymentNotice />} />
    <Route path="new" element={<PaymentNoticeUpdate />} />
    <Route path=":id">
      <Route index element={<PaymentNoticeDetail />} />
      <Route path="edit" element={<PaymentNoticeUpdate />} />
      <Route path="delete" element={<PaymentNoticeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PaymentNoticeRoutes;
