import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { PaymentEventType } from 'app/shared/model/enumerations/payment-event-type.model';

export interface IPaymentNotice {
  id?: string;
  noticeDate?: string;
  eventType?: keyof typeof PaymentEventType;
  amount?: number;
  message?: string;
  salesContract?: ISalesContract | null;
}

export const defaultValue: Readonly<IPaymentNotice> = {};
