import { IShippingDocs } from 'app/shared/model/shipping-docs.model';

export interface IArrivalNotice {
  id?: string;
  lotNo?: string;
  masterBLNo?: string;
  preparedBy?: string;
  consignee?: string;
  amsBLNo?: string;
  vesselVoyNo?: string;
  moveType?: string;
  portOfLoading?: string;
  etd?: string;
  eta?: string;
  iTNo?: string;
  issuedPlace?: string;
  invoiceDate?: string | null;
  containerNo?: string | null;
  containerSize?: string | null;
  sealNo?: string | null;
  pkgNo?: number | null;
  totalAmount?: number | null;
  shippingDocs?: IShippingDocs | null;
}

export const defaultValue: Readonly<IArrivalNotice> = {};
