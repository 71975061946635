import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './sales-contract.reducer';

export const SalesContract = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const salesContractList = useAppSelector(state => state.salesContract.entities);
  const loading = useAppSelector(state => state.salesContract.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="sales-contract-heading" data-cy="SalesContractHeading">
        <Translate contentKey="suretracBooksApp.salesContract.home.title">Sales Contracts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="suretracBooksApp.salesContract.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/sales-contract/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="suretracBooksApp.salesContract.home.createLabel">Create new Sales Contract</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {salesContractList && salesContractList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="suretracBooksApp.salesContract.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('contractId')}>
                  <Translate contentKey="suretracBooksApp.salesContract.contractId">Contract Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('contractId')} />
                </th>
                <th className="hand" onClick={sort('deposit')}>
                  <Translate contentKey="suretracBooksApp.salesContract.deposit">Deposit</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('deposit')} />
                </th>
                <th className="hand" onClick={sort('blance')}>
                  <Translate contentKey="suretracBooksApp.salesContract.blance">Blance</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('blance')} />
                </th>
                <th className="hand" onClick={sort('shipmentPort')}>
                  <Translate contentKey="suretracBooksApp.salesContract.shipmentPort">Shipment Port</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('shipmentPort')} />
                </th>
                <th className="hand" onClick={sort('dischargePlace')}>
                  <Translate contentKey="suretracBooksApp.salesContract.dischargePlace">Discharge Place</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dischargePlace')} />
                </th>
                <th className="hand" onClick={sort('piValidity')}>
                  <Translate contentKey="suretracBooksApp.salesContract.piValidity">Pi Validity</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('piValidity')} />
                </th>
                <th className="hand" onClick={sort('placedDate')}>
                  <Translate contentKey="suretracBooksApp.salesContract.placedDate">Placed Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('placedDate')} />
                </th>
                <th className="hand" onClick={sort('moreOrLess')}>
                  <Translate contentKey="suretracBooksApp.salesContract.moreOrLess">More Or Less</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('moreOrLess')} />
                </th>
                <th className="hand" onClick={sort('totalPrice')}>
                  <Translate contentKey="suretracBooksApp.salesContract.totalPrice">Total Price</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('totalPrice')} />
                </th>
                <th className="hand" onClick={sort('importType')}>
                  <Translate contentKey="suretracBooksApp.salesContract.importType">Import Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('importType')} />
                </th>
                <th className="hand" onClick={sort('terms')}>
                  <Translate contentKey="suretracBooksApp.salesContract.terms">Terms</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('terms')} />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.salesContract.purchaseInvoice">Purchase Invoice</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.salesContract.purchaseOrder">Purchase Order</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.salesContract.bill">Bill</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.salesContract.invoice">Invoice</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.salesContract.shippingDocs">Shipping Docs</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.salesContract.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {salesContractList.map((salesContract, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/sales-contract/${salesContract.id}`} color="link" size="sm">
                      {salesContract.id}
                    </Button>
                  </td>
                  <td>{salesContract.contractId}</td>
                  <td>{salesContract.deposit}</td>
                  <td>{salesContract.blance}</td>
                  <td>{salesContract.shipmentPort}</td>
                  <td>{salesContract.dischargePlace}</td>
                  <td>{salesContract.piValidity}</td>
                  <td>
                    {salesContract.placedDate ? <TextFormat type="date" value={salesContract.placedDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{salesContract.moreOrLess}</td>
                  <td>{salesContract.totalPrice}</td>
                  <td>
                    <Translate contentKey={`suretracBooksApp.ImportType.${salesContract.importType}`} />
                  </td>
                  <td>{salesContract.terms}</td>
                  <td>
                    {salesContract.purchaseInvoice ? (
                      <Link to={`/purchase-invoice/${salesContract.purchaseInvoice.id}`}>{salesContract.purchaseInvoice.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {salesContract.purchaseOrder ? (
                      <Link to={`/purchase-order/${salesContract.purchaseOrder.id}`}>{salesContract.purchaseOrder.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{salesContract.bill ? <Link to={`/bill/${salesContract.bill.id}`}>{salesContract.bill.id}</Link> : ''}</td>
                  <td>
                    {salesContract.invoice ? <Link to={`/invoice/${salesContract.invoice.id}`}>{salesContract.invoice.id}</Link> : ''}
                  </td>
                  <td>
                    {salesContract.shippingDocs ? (
                      <Link to={`/shipping-docs/${salesContract.shippingDocs.id}`}>{salesContract.shippingDocs.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{salesContract.user ? salesContract.user.login : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/sales-contract/${salesContract.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/sales-contract/${salesContract.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (location.href = `/sales-contract/${salesContract.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="suretracBooksApp.salesContract.home.notFound">No Sales Contracts found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SalesContract;
