import product from 'app/entities/product/product.reducer';
import factory from 'app/entities/factory/factory.reducer';
import productItemPrice from 'app/entities/product-item-price/product-item-price.reducer';
import productPackList from 'app/entities/product-pack-list/product-pack-list.reducer';
import shippingNotice from 'app/entities/shipping-notice/shipping-notice.reducer';
import paymentNotice from 'app/entities/payment-notice/payment-notice.reducer';
import scheduledAlert from 'app/entities/scheduled-alert/scheduled-alert.reducer';
import salesContract from 'app/entities/sales-contract/sales-contract.reducer';
import purchaseInvoice from 'app/entities/purchase-invoice/purchase-invoice.reducer';
import purchaseOrder from 'app/entities/purchase-order/purchase-order.reducer';
import bill from 'app/entities/bill/bill.reducer';
import invoice from 'app/entities/invoice/invoice.reducer';
import productBasePrice from 'app/entities/product-base-price/product-base-price.reducer';
import regionalPriceList from 'app/entities/regional-price-list/regional-price-list.reducer';
import customerPriceList from 'app/entities/customer-price-list/customer-price-list.reducer';
import customerDetails from 'app/entities/customer-details/customer-details.reducer';
import shipBooking from 'app/entities/ship-booking/ship-booking.reducer';
import billOfLading from 'app/entities/bill-of-lading/bill-of-lading.reducer';
import arrivalNotice from 'app/entities/arrival-notice/arrival-notice.reducer';
import shippingDocs from 'app/entities/shipping-docs/shipping-docs.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  product,
  factory,
  productItemPrice,
  productPackList,
  shippingNotice,
  paymentNotice,
  scheduledAlert,
  salesContract,
  purchaseInvoice,
  purchaseOrder,
  bill,
  invoice,
  productBasePrice,
  regionalPriceList,
  customerPriceList,
  customerDetails,
  shipBooking,
  billOfLading,
  arrivalNotice,
  shippingDocs,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
