import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ArrivalNotice from './arrival-notice';
import ArrivalNoticeDetail from './arrival-notice-detail';
import ArrivalNoticeUpdate from './arrival-notice-update';
import ArrivalNoticeDeleteDialog from './arrival-notice-delete-dialog';

const ArrivalNoticeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ArrivalNotice />} />
    <Route path="new" element={<ArrivalNoticeUpdate />} />
    <Route path=":id">
      <Route index element={<ArrivalNoticeDetail />} />
      <Route path="edit" element={<ArrivalNoticeUpdate />} />
      <Route path="delete" element={<ArrivalNoticeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ArrivalNoticeRoutes;
