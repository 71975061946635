import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './bill-of-lading.reducer';

export const BillOfLadingDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const billOfLadingEntity = useAppSelector(state => state.billOfLading.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="billOfLadingDetailsHeading">
          <Translate contentKey="suretracBooksApp.billOfLading.detail.title">BillOfLading</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.id}</dd>
          <dt>
            <span id="billOfLadingNo">
              <Translate contentKey="suretracBooksApp.billOfLading.billOfLadingNo">Bill Of Lading No</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.billOfLadingNo}</dd>
          <dt>
            <span id="consignee">
              <Translate contentKey="suretracBooksApp.billOfLading.consignee">Consignee</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.consignee}</dd>
          <dt>
            <span id="notifyParty">
              <Translate contentKey="suretracBooksApp.billOfLading.notifyParty">Notify Party</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.notifyParty}</dd>
          <dt>
            <span id="placeOfReceipt">
              <Translate contentKey="suretracBooksApp.billOfLading.placeOfReceipt">Place Of Receipt</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.placeOfReceipt}</dd>
          <dt>
            <span id="portOfLoading">
              <Translate contentKey="suretracBooksApp.billOfLading.portOfLoading">Port Of Loading</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.portOfLoading}</dd>
          <dt>
            <span id="vesselVoyage">
              <Translate contentKey="suretracBooksApp.billOfLading.vesselVoyage">Vessel Voyage</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.vesselVoyage}</dd>
          <dt>
            <span id="portOfDischarge">
              <Translate contentKey="suretracBooksApp.billOfLading.portOfDischarge">Port Of Discharge</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.portOfDischarge}</dd>
          <dt>
            <span id="containerNo">
              <Translate contentKey="suretracBooksApp.billOfLading.containerNo">Container No</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.containerNo}</dd>
          <dt>
            <span id="sealNo">
              <Translate contentKey="suretracBooksApp.billOfLading.sealNo">Seal No</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.sealNo}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="suretracBooksApp.billOfLading.description">Description</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.description}</dd>
          <dt>
            <span id="grossWeight">
              <Translate contentKey="suretracBooksApp.billOfLading.grossWeight">Gross Weight</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.grossWeight}</dd>
          <dt>
            <span id="measurement">
              <Translate contentKey="suretracBooksApp.billOfLading.measurement">Measurement</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.measurement}</dd>
          <dt>
            <span id="telexRelease">
              <Translate contentKey="suretracBooksApp.billOfLading.telexRelease">Telex Release</Translate>
            </span>
          </dt>
          <dd>{billOfLadingEntity.telexRelease ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="suretracBooksApp.billOfLading.shippingDocs">Shipping Docs</Translate>
          </dt>
          <dd>{billOfLadingEntity.shippingDocs ? billOfLadingEntity.shippingDocs.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/bill-of-lading" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/bill-of-lading/${billOfLadingEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BillOfLadingDetail;
