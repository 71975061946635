import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IFactory } from 'app/shared/model/factory.model';
import { getEntities as getFactories } from 'app/entities/factory/factory.reducer';
import { ISalesContract } from 'app/shared/model/sales-contract.model';
import { getEntities as getSalesContracts } from 'app/entities/sales-contract/sales-contract.reducer';
import { IBill } from 'app/shared/model/bill.model';
import { getEntity, updateEntity, createEntity, reset } from './bill.reducer';

export const BillUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const factories = useAppSelector(state => state.factory.entities);
  const salesContracts = useAppSelector(state => state.salesContract.entities);
  const billEntity = useAppSelector(state => state.bill.entity);
  const loading = useAppSelector(state => state.bill.loading);
  const updating = useAppSelector(state => state.bill.updating);
  const updateSuccess = useAppSelector(state => state.bill.updateSuccess);

  const handleClose = () => {
    navigate('/bill');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getFactories({}));
    dispatch(getSalesContracts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.totalHQ !== undefined && typeof values.totalHQ !== 'number') {
      values.totalHQ = Number(values.totalHQ);
    }
    values.estimatedDeparture = convertDateTimeToServer(values.estimatedDeparture);

    const entity = {
      ...billEntity,
      ...values,
      factory: factories.find(it => it.id.toString() === values.factory.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          estimatedDeparture: displayDefaultDateTime(),
        }
      : {
          ...billEntity,
          estimatedDeparture: convertDateTimeFromServer(billEntity.estimatedDeparture),
          factory: billEntity?.factory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="suretracBooksApp.bill.home.createOrEditLabel" data-cy="BillCreateUpdateHeading">
            <Translate contentKey="suretracBooksApp.bill.home.createOrEditLabel">Create or edit a Bill</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bill-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('suretracBooksApp.bill.billNo')}
                id="bill-billNo"
                name="billNo"
                data-cy="billNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.bill.taxNo')}
                id="bill-taxNo"
                name="taxNo"
                data-cy="taxNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.bill.soldTo')}
                id="bill-soldTo"
                name="soldTo"
                data-cy="soldTo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.bill.from')}
                id="bill-from"
                name="from"
                data-cy="from"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.bill.toAddress')}
                id="bill-toAddress"
                name="toAddress"
                data-cy="toAddress"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.bill.totalHQ')}
                id="bill-totalHQ"
                name="totalHQ"
                data-cy="totalHQ"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.bill.estimatedDeparture')}
                id="bill-estimatedDeparture"
                name="estimatedDeparture"
                data-cy="estimatedDeparture"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="bill-factory"
                name="factory"
                data-cy="factory"
                label={translate('suretracBooksApp.bill.factory')}
                type="select"
              >
                <option value="" key="0" />
                {factories
                  ? factories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/bill" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BillUpdate;
