import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/product">
        <Translate contentKey="global.menu.entities.product" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/factory">
        <Translate contentKey="global.menu.entities.factory" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product-item-price">
        <Translate contentKey="global.menu.entities.productItemPrice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product-pack-list">
        <Translate contentKey="global.menu.entities.productPackList" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/shipping-notice">
        <Translate contentKey="global.menu.entities.shippingNotice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/payment-notice">
        <Translate contentKey="global.menu.entities.paymentNotice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/scheduled-alert">
        <Translate contentKey="global.menu.entities.scheduledAlert" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sales-contract">
        <Translate contentKey="global.menu.entities.salesContract" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/purchase-invoice">
        <Translate contentKey="global.menu.entities.purchaseInvoice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/purchase-order">
        <Translate contentKey="global.menu.entities.purchaseOrder" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/bill">
        <Translate contentKey="global.menu.entities.bill" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/invoice">
        <Translate contentKey="global.menu.entities.invoice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product-base-price">
        <Translate contentKey="global.menu.entities.productBasePrice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/regional-price-list">
        <Translate contentKey="global.menu.entities.regionalPriceList" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/customer-price-list">
        <Translate contentKey="global.menu.entities.customerPriceList" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/customer-details">
        <Translate contentKey="global.menu.entities.customerDetails" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ship-booking">
        <Translate contentKey="global.menu.entities.shipBooking" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/bill-of-lading">
        <Translate contentKey="global.menu.entities.billOfLading" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/arrival-notice">
        <Translate contentKey="global.menu.entities.arrivalNotice" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/shipping-docs">
        <Translate contentKey="global.menu.entities.shippingDocs" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
