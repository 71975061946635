import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './arrival-notice.reducer';

export const ArrivalNotice = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const arrivalNoticeList = useAppSelector(state => state.arrivalNotice.entities);
  const loading = useAppSelector(state => state.arrivalNotice.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="arrival-notice-heading" data-cy="ArrivalNoticeHeading">
        <Translate contentKey="suretracBooksApp.arrivalNotice.home.title">Arrival Notices</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="suretracBooksApp.arrivalNotice.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/arrival-notice/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="suretracBooksApp.arrivalNotice.home.createLabel">Create new Arrival Notice</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {arrivalNoticeList && arrivalNoticeList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('lotNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.lotNo">Lot No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lotNo')} />
                </th>
                <th className="hand" onClick={sort('masterBLNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.masterBLNo">Master BL No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('masterBLNo')} />
                </th>
                <th className="hand" onClick={sort('preparedBy')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.preparedBy">Prepared By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('preparedBy')} />
                </th>
                <th className="hand" onClick={sort('consignee')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.consignee">Consignee</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('consignee')} />
                </th>
                <th className="hand" onClick={sort('amsBLNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.amsBLNo">Ams BL No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('amsBLNo')} />
                </th>
                <th className="hand" onClick={sort('vesselVoyNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.vesselVoyNo">Vessel Voy No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('vesselVoyNo')} />
                </th>
                <th className="hand" onClick={sort('moveType')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.moveType">Move Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('moveType')} />
                </th>
                <th className="hand" onClick={sort('portOfLoading')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.portOfLoading">Port Of Loading</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('portOfLoading')} />
                </th>
                <th className="hand" onClick={sort('etd')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.etd">Etd</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('etd')} />
                </th>
                <th className="hand" onClick={sort('eta')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.eta">Eta</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('eta')} />
                </th>
                <th className="hand" onClick={sort('iTNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.iTNo">I T No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('iTNo')} />
                </th>
                <th className="hand" onClick={sort('issuedPlace')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.issuedPlace">Issued Place</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('issuedPlace')} />
                </th>
                <th className="hand" onClick={sort('invoiceDate')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.invoiceDate">Invoice Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('invoiceDate')} />
                </th>
                <th className="hand" onClick={sort('containerNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.containerNo">Container No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('containerNo')} />
                </th>
                <th className="hand" onClick={sort('containerSize')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.containerSize">Container Size</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('containerSize')} />
                </th>
                <th className="hand" onClick={sort('sealNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.sealNo">Seal No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('sealNo')} />
                </th>
                <th className="hand" onClick={sort('pkgNo')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.pkgNo">Pkg No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('pkgNo')} />
                </th>
                <th className="hand" onClick={sort('totalAmount')}>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.totalAmount">Total Amount</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('totalAmount')} />
                </th>
                <th>
                  <Translate contentKey="suretracBooksApp.arrivalNotice.shippingDocs">Shipping Docs</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {arrivalNoticeList.map((arrivalNotice, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/arrival-notice/${arrivalNotice.id}`} color="link" size="sm">
                      {arrivalNotice.id}
                    </Button>
                  </td>
                  <td>{arrivalNotice.lotNo}</td>
                  <td>{arrivalNotice.masterBLNo}</td>
                  <td>{arrivalNotice.preparedBy}</td>
                  <td>{arrivalNotice.consignee}</td>
                  <td>{arrivalNotice.amsBLNo}</td>
                  <td>{arrivalNotice.vesselVoyNo}</td>
                  <td>{arrivalNotice.moveType}</td>
                  <td>{arrivalNotice.portOfLoading}</td>
                  <td>{arrivalNotice.etd ? <TextFormat type="date" value={arrivalNotice.etd} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{arrivalNotice.eta ? <TextFormat type="date" value={arrivalNotice.eta} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{arrivalNotice.iTNo}</td>
                  <td>
                    {arrivalNotice.issuedPlace ? (
                      <TextFormat type="date" value={arrivalNotice.issuedPlace} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {arrivalNotice.invoiceDate ? (
                      <TextFormat type="date" value={arrivalNotice.invoiceDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{arrivalNotice.containerNo}</td>
                  <td>{arrivalNotice.containerSize}</td>
                  <td>{arrivalNotice.sealNo}</td>
                  <td>{arrivalNotice.pkgNo}</td>
                  <td>{arrivalNotice.totalAmount}</td>
                  <td>
                    {arrivalNotice.shippingDocs ? (
                      <Link to={`/shipping-docs/${arrivalNotice.shippingDocs.id}`}>{arrivalNotice.shippingDocs.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/arrival-notice/${arrivalNotice.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/arrival-notice/${arrivalNotice.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (location.href = `/arrival-notice/${arrivalNotice.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="suretracBooksApp.arrivalNotice.home.notFound">No Arrival Notices found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ArrivalNotice;
