import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IShippingDocs } from 'app/shared/model/shipping-docs.model';
import { getEntities as getShippingDocs } from 'app/entities/shipping-docs/shipping-docs.reducer';
import { IShipBooking } from 'app/shared/model/ship-booking.model';
import { getEntity, updateEntity, createEntity, reset } from './ship-booking.reducer';

export const ShipBookingUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const shippingDocs = useAppSelector(state => state.shippingDocs.entities);
  const shipBookingEntity = useAppSelector(state => state.shipBooking.entity);
  const loading = useAppSelector(state => state.shipBooking.loading);
  const updating = useAppSelector(state => state.shipBooking.updating);
  const updateSuccess = useAppSelector(state => state.shipBooking.updateSuccess);

  const handleClose = () => {
    navigate('/ship-booking');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getShippingDocs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    values.bookingDate = convertDateTimeToServer(values.bookingDate);
    values.etd = convertDateTimeToServer(values.etd);
    values.eta = convertDateTimeToServer(values.eta);
    values.cyDate = convertDateTimeToServer(values.cyDate);
    values.returnDate = convertDateTimeToServer(values.returnDate);
    values.closingTime = convertDateTimeToServer(values.closingTime);
    if (values.carrierBookingNo !== undefined && typeof values.carrierBookingNo !== 'number') {
      values.carrierBookingNo = Number(values.carrierBookingNo);
    }
    values.cutOffTime = convertDateTimeToServer(values.cutOffTime);

    const entity = {
      ...shipBookingEntity,
      ...values,
      shippingDocs: shippingDocs.find(it => it.id.toString() === values.shippingDocs.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          bookingDate: displayDefaultDateTime(),
          etd: displayDefaultDateTime(),
          eta: displayDefaultDateTime(),
          cyDate: displayDefaultDateTime(),
          returnDate: displayDefaultDateTime(),
          closingTime: displayDefaultDateTime(),
          cutOffTime: displayDefaultDateTime(),
        }
      : {
          ...shipBookingEntity,
          bookingDate: convertDateTimeFromServer(shipBookingEntity.bookingDate),
          etd: convertDateTimeFromServer(shipBookingEntity.etd),
          eta: convertDateTimeFromServer(shipBookingEntity.eta),
          cyDate: convertDateTimeFromServer(shipBookingEntity.cyDate),
          returnDate: convertDateTimeFromServer(shipBookingEntity.returnDate),
          closingTime: convertDateTimeFromServer(shipBookingEntity.closingTime),
          cutOffTime: convertDateTimeFromServer(shipBookingEntity.cutOffTime),
          shippingDocs: shipBookingEntity?.shippingDocs?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="suretracBooksApp.shipBooking.home.createOrEditLabel" data-cy="ShipBookingCreateUpdateHeading">
            <Translate contentKey="suretracBooksApp.shipBooking.home.createOrEditLabel">Create or edit a ShipBooking</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ship-booking-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.bookingId')}
                id="ship-booking-bookingId"
                name="bookingId"
                data-cy="bookingId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.bookingType')}
                id="ship-booking-bookingType"
                name="bookingType"
                data-cy="bookingType"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.bookingDate')}
                id="ship-booking-bookingDate"
                name="bookingDate"
                data-cy="bookingDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.containerType')}
                id="ship-booking-containerType"
                name="containerType"
                data-cy="containerType"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.consignee')}
                id="ship-booking-consignee"
                name="consignee"
                data-cy="consignee"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.placeOfReceipt')}
                id="ship-booking-placeOfReceipt"
                name="placeOfReceipt"
                data-cy="placeOfReceipt"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.portOfLoading')}
                id="ship-booking-portOfLoading"
                name="portOfLoading"
                data-cy="portOfLoading"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.fobAt')}
                id="ship-booking-fobAt"
                name="fobAt"
                data-cy="fobAt"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.feeder')}
                id="ship-booking-feeder"
                name="feeder"
                data-cy="feeder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.motherVessel')}
                id="ship-booking-motherVessel"
                name="motherVessel"
                data-cy="motherVessel"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.portOfDischarge')}
                id="ship-booking-portOfDischarge"
                name="portOfDischarge"
                data-cy="portOfDischarge"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.portOfDelivery')}
                id="ship-booking-portOfDelivery"
                name="portOfDelivery"
                data-cy="portOfDelivery"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.finalDest')}
                id="ship-booking-finalDest"
                name="finalDest"
                data-cy="finalDest"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.etd')}
                id="ship-booking-etd"
                name="etd"
                data-cy="etd"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.eta')}
                id="ship-booking-eta"
                name="eta"
                data-cy="eta"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.cyDate')}
                id="ship-booking-cyDate"
                name="cyDate"
                data-cy="cyDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.returnDate')}
                id="ship-booking-returnDate"
                name="returnDate"
                data-cy="returnDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.cyYard')}
                id="ship-booking-cyYard"
                name="cyYard"
                data-cy="cyYard"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.contact')}
                id="ship-booking-contact"
                name="contact"
                data-cy="contact"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.returnYard')}
                id="ship-booking-returnYard"
                name="returnYard"
                data-cy="returnYard"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.yardRemark')}
                id="ship-booking-yardRemark"
                name="yardRemark"
                data-cy="yardRemark"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.closingTime')}
                id="ship-booking-closingTime"
                name="closingTime"
                data-cy="closingTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.carrierBookingNo')}
                id="ship-booking-carrierBookingNo"
                name="carrierBookingNo"
                data-cy="carrierBookingNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.carrier')}
                id="ship-booking-carrier"
                name="carrier"
                data-cy="carrier"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('suretracBooksApp.shipBooking.cutOffTime')}
                id="ship-booking-cutOffTime"
                name="cutOffTime"
                data-cy="cutOffTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="ship-booking-shippingDocs"
                name="shippingDocs"
                data-cy="shippingDocs"
                label={translate('suretracBooksApp.shipBooking.shippingDocs')}
                type="select"
              >
                <option value="" key="0" />
                {shippingDocs
                  ? shippingDocs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ship-booking" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ShipBookingUpdate;
