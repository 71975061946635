import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ScheduledAlert from './scheduled-alert';
import ScheduledAlertDetail from './scheduled-alert-detail';
import ScheduledAlertUpdate from './scheduled-alert-update';
import ScheduledAlertDeleteDialog from './scheduled-alert-delete-dialog';

const ScheduledAlertRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ScheduledAlert />} />
    <Route path="new" element={<ScheduledAlertUpdate />} />
    <Route path=":id">
      <Route index element={<ScheduledAlertDetail />} />
      <Route path="edit" element={<ScheduledAlertUpdate />} />
      <Route path="delete" element={<ScheduledAlertDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ScheduledAlertRoutes;
